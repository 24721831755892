<template>
  <footer>
    <div class="inner">
      <div class="contact">
        <h3>Contact</h3>
      
        <div class="row">
          <img src="../../assets/images/feather/phone.svg">
          <span>0474 47 14 63</span>
        </div>

        <div class="row">
          <img src="../../assets/images/feather/at-sign.svg">
          <a href="mailto:areadne.bvba@gmail.com">areadne.bvba@gmail.com</a>
        </div>

        <div class="row">
          <img src="../../assets/images/feather/briefcase.svg">
          <span>BE 0816.915.291</span>
        </div>
      </div>

      <div class="socials" style="display: none;">
        <h3>Socials</h3>

        <a href="" target="_blank"><img src="@/assets/images/feather/facebook.svg"></a>
        <a href="" target="_blank"><img src="@/assets/images/feather/instagram.svg"></a>
        <a href="" target="_blank"><img src="@/assets/images/feather/twitter.svg"></a>
        <a href="" target="_blank"><img src="@/assets/images/feather/youtube.svg"></a>
      </div>
    </div>

    <div class="small-footer">
      <div class="small-inner">
        <router-link to="/privacy">Privacy Policy</router-link>
        &nbsp;-&nbsp;
        <router-link to="/sale-conditions">Sale Conditions</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss" scoped>
  footer {
    background-color: black;
    color: white;

    .inner {
      width: 80%;
      height: 100%;
      max-width: 1300px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @include tablet {
        flex-direction: row;
      }
    }
  }

  img {
    filter: invert(1);
  }

  .contact {
    margin: auto auto auto 10%;

    .row {
      display: flex;
      align-items: center;
      margin: 10px 0;
      
      span, a {
        margin-left: 10px;
        font-family: 'Sahar-Regular';
        color: white;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .socials {
    margin: auto auto auto 10%;

    @include tablet {
      margin: auto 10% auto auto;

      h3 {
        text-align: right;
      }
    }

    img {
      margin: 10px;
      cursor: pointer;
    }
  }

  .small-footer {
    height: auto;
    background-color: black;
    color: white;
    padding: 10px 0 30px 0;

    .small-inner {
      text-align: center;
    }

    a {
      color: white;
      font-family: 'Sahar-Regular';
      margin: 5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>