<template>
  <div class="shop">
    <ShopFilter :category="category.toUpperCase()" />
    <ItemList :items="items" />
    <BigButton class="btnMore" ref="btnMore" @click.native="viewMore" v-if="!loadedEverything">View more</BigButton>
  </div>
</template>

<script>
import firebase from 'firebase';

import ShopFilter from '@/components/shop/ShopFilter';
import ItemList from '@/components/shop/ItemList';

export default {
  name: 'Shop',
  props: {
    category: {
      type: String,
      default() {
        return "ALL";
      }
    }
  },
  data() {
    return {
      items: undefined,
      lastRetrievedItem: undefined,
      isLoadingMoreProducts: false,
      loadedEverything: false
    }
  },
  methods: {
    getProducts() {
      this.loadedEverything = false;

      var query = firebase.firestore().collection("products");

      if (this.$props.category != 'ALL') {
        query = query.where("category", "==", decodeURI(this.$props.category.toUpperCase()));
      }
      
      query = query.where("hidden", "==", false);

      query.orderBy("created_at", "desc").limit(12).get()
        .then((querySnapshot) => {
          this.items = [];
          
          querySnapshot.forEach((doc) => {
            var product = doc.data();
            product.id = doc.id
            this.items.push(product);
          });

          this.lastRetrievedItem = querySnapshot.docs[querySnapshot.docs.length - 1];

          if (querySnapshot.docs.length < 12) {
            this.loadedEverything = true;
          }
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    viewMore() {
      this.$refs.btnMore.load();

      var query = firebase.firestore().collection("products");

      if (this.$props.category != 'ALL') {
        query = query.where("category", "==", this.$props.category.toUpperCase())
      }

      query.orderBy("created_at", "desc").startAfter(this.lastRetrievedItem).limit(12).get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var product = doc.data();
          product.id = doc.id
          this.items.push(product);
        });

        this.lastRetrievedItem = querySnapshot.docs[querySnapshot.docs.length - 1];

        if (querySnapshot.docs.length < 12) {
          this.loadedEverything = true;
        }

        this.$refs.btnMore.complete();
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    }
  },
  created() {
    this.getProducts()
  },
  components: {
    ShopFilter,
    ItemList
  }
}
</script>

<style lang="scss" scoped>
  .shop {

  }

  .btnMore {
    margin: auto;
  }
</style>