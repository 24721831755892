<template>
  <nav class="mobile-header" v-bind:class="{ scrolled: scrolled, expanded: expandedNav }">
    <div class="top">
      <a class="menu-button" v-bind:class="{'is-active': expandedNav}" id="menuButton" @click="toggleNav"><span class="burger-icon"></span></a>

      <router-link to="/" class="logo">
        <img src="@/assets/images/logo.jpg" />
      </router-link>

      <div class="cart-button" @click="goToCart">
        <img src="@/assets/images/feather/shopping-cart.svg">
        <div v-if="cart_items_count > 0" class="cart-item-count">{{ cart_items_count }}</div>
      </div>
    </div>

    <div class="nav" v-bind:class="{ visible: expandedNav }">
      <router-link :to="'/'">HOME</router-link>
      <router-link :to="'/about'">ABOUT US</router-link>
      <router-link :to="'/shop'">SHOP</router-link>
      <router-link :to="'/cart'">SHOPPING CART <span v-if="cart_items_count > 0">({{ cart_items_count }})</span></router-link>
      <router-link :to="'/contact'">CONTACT</router-link>
    </div>
  </nav>
</template>

<script>
import { shopMixins } from '@/mixins/shop';

export default {
  name: 'MobileHeader',
  mixins: [shopMixins],
  data() {
    return {
      scrolled: false,
      expandedNav: false
    }
  },
  watch: {
    $route() {
      this.expandedNav = false;
    }
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return
      }
      // Here we determine whether we need to show or hide the navbar
      this.scrolled = currentScrollPosition > 20
    },
    toggleNav() {
      this.expandedNav = !this.expandedNav;
    },
    goToCart() {
      this.$router.push({ path: '/cart' });
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style lang="scss" scoped>
  .mobile-header {
    position: relative;
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    padding: 0 5%;
    z-index: 7;
    background: #fff;
    overflow: hidden;
    transition: all .25s ease-in-out;

    &.scrolled {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &.expanded {
      height: 100%;
    }
  }

  .top {
    width: 90%;
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0;
    left: 5%;
  }
  
  .menu-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
  }

  .burger-icon {
    position: relative;
    user-select: none;
    width: 15px;
  }
  .burger-icon, .burger-icon::before, .burger-icon::after {
    display: block;
    height: 2px;
    background-color: black;
    outline: 1px solid transparent;
    transition-property: background-color, transform, width;
    transition-duration: 0.3s;
  }
  .burger-icon::before, .burger-icon::after {
    position: absolute;
    content: "";
  }
  .burger-icon::before {
    top: -6px;
    width: 20px;
  }
  .burger-icon::after {
    top: 6px;
    width: 10px;
  }
  .menu-button.is-active .burger-icon {
    background-color: transparent;
  }
  .menu-button.is-active .burger-icon::before {
    transform: translateY(6px) rotate(45deg);
  }
  .menu-button.is-active .burger-icon::after {
    transform: translateY(-6px) rotate(-45deg);
    width: 20px;
  }

  .logo {
    width: 30%;
    height: 100%;
    max-height: 40px;
    display: flex;

    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);

    img {
      object-fit: contain;
      align-self: center;
      width: 100%;
      height: 100%;
    }
  }

  .cart-button {
    display: flex;
    align-items: center;
    margin: 0 10px;
    padding: 0 5px;
    cursor: pointer;
    transition-duration: .3s;
    
    &:hover {
      opacity: 0.4;
    }

    img {
      width: 20px;
    }
  }

  .cart-item-count {
    background-color: black;
    color: white;
    height: 20px;
    font-size: 11px;
    padding: 0 10px;
    padding-top: 2px;
    margin-left: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    font-family: 'Sahar-Bold';
  }




  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition-duration: .3s;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    a, span {
      color: black;
      font-family: 'Sahar-Bold';
      font-size: 20px;
      margin: 10px 0;
      padding: 5px 0;
    }
  }
</style>