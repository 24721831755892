<template>
  <div class="item" v-bind:class="{ soldOut: soldOut }" @click="goToItem">
    <div class="image-container noselect" v-bind:class="{ failed: imageFailed }">
      <img :src="imageSource" @error="picLoadError" />
    </div>
    <span class="name" v-if="product.name">{{ product.name }}</span>
    <span class="price" v-if="product.price">{{ formatPrice(product.price) }}</span>
  </div>
</template>

<script>
export default {
  name: "Item",
  props: ["item"],
  data() {
    return {
      product: {},
      imageFailed: false,
      imageSource: ''
    }
  },
  watch: {
    item(val) {
      this.product = val;
      this.imageFailed = false;
      this.imageSource = `https://firebasestorage.googleapis.com/v0/b/webshop2-376c7.appspot.com/o/products%2F${val.id}.jpg?alt=media`
    }
  },
  computed: {
    soldOut() {
      var totalStock = 0, hasVariationWithUnlimitedStock = false;

      this.$props.item.stock.forEach(variationStock => {
        if (variationStock.quantity == "Unlimited") {
          hasVariationWithUnlimitedStock = true;
        } else {
          totalStock += variationStock.quantity;
        }
      });
      
      return hasVariationWithUnlimitedStock ? false : totalStock == 0;
    }
  },
  methods: {
    goToItem() {
      this.$router.push({ path: '/item/' + this.product.id });
    },
    formatPrice(price) {
      return isNaN(price) ? "" : "€ " + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace('.', ', ');
    },
    picLoadError () {
      this.imageFailed = true;
      this.imageSource = require('@/assets/images/feather/image.svg');
    }
  },
  created() {
    this.product = this.$props.item;
    this.imageFailed = false;
    this.imageSource = `https://firebasestorage.googleapis.com/v0/b/webshop2-376c7.appspot.com/o/products%2F${this.$props.item.id}.jpg?alt=media`
  }
}
</script>

<style lang="scss" scoped>
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    cursor: pointer;

    &.soldOut {
      opacity: .4;
    }

    &:hover {
      .image-container:not(.failed) {
        img {
          transform: scale(1.05);
        }
      }

      .name, .price {
        opacity: .4;
      }
    }
  }

  .image-container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 100% 0 0 0;
    margin: 0 auto 10px auto;

    &.failed {
      background-color: #ECECEC;
      
      img {
        width: 30%;
        margin: auto;
        transition-duration: 0s;
      }
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition-duration: .3s;
      margin: 0 auto;
    }
  }

  .name {
    font-family: "Sahar-Bold";
    transition-duration: .3s;
    text-transform: uppercase;
  }

  .price {
    font-family: "Sahar-Regular";
    transition-duration: .3s;
  }
</style>