import Vue from 'vue'
import firebase from 'firebase'
import App from './App.vue'
import router from './router'
import store from './store'

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCSCk_myqsfREZWsv74mnXYUzEoRU3qzC4', // gemaakt bij https://console.cloud.google.com/apis/credentials
    //libraries: 'places' ( of keuze uit places,drawing,visualization, ...)
  },
  installComponents: true
})

import BigButton from "./components/global/BigButton.vue"
Vue.component("BigButton", BigButton)
import BigSelect from "./components/global/BigSelect.vue"
Vue.component("BigSelect", BigSelect)

import Default from "./layouts/Default.vue"
Vue.component("default-layout", Default)

Vue.config.productionTip = false

let app = ''

firebase.initializeApp({
  apiKey: "AIzaSyATCRQGf7pgsO6STLsS-1vlZd3FHPfOfro",
  authDomain: "webshop2-376c7.firebaseapp.com",
  databaseURL: "https://webshop2-376c7.firebaseio.com",
  projectId: "webshop2-376c7",
  storageBucket: "webshop2-376c7.appspot.com",
  messagingSenderId: "319172739026",
  appId: "1:319172739026:web:fcc3f75f9c07839649d20b"
})

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})