const state = { 
    categories: [],
    navCategories: []
};

const getters = { 
    getCategories: state => state.categories,
    getNavCategories: state => state.navCategories,
};

const actions = {
    setCategories({commit}, categories) {
        commit("setCategories", categories);
    },
    setNavCategories({commit}, navCategories) {
        commit("setNavCategories", navCategories);
    }
};

const mutations = {
    setCategories(state, categories) {
        state.categories = categories;
    },
    setNavCategories(state, navCategories) {
        state.navCategories = navCategories;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}