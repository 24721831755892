<template>
  <nav class="header" v-bind:class="{ scrolled: scrolled }">
    <div class="inner">
      <router-link to="/" class="logo">
        <img src="@/assets/images/logo.jpg" />
      </router-link>

      <div class="nav">
        <router-link to="/about">ABOUT US</router-link>
        <router-link to="/shop">SHOP</router-link>
        <div class="v-line" />
        <router-link v-for="category in getNavCategories()" :key="category" :to="`/shop/${category.toLowerCase()}`">{{ category }}</router-link>
      </div>

      <div class="icon-buttons noselect">
        <!--
        <img src="@/assets/images/feather/search.svg">
        <img src="@/assets/images/feather/user.svg">
        -->

        <div class="cart-button" @click="goToCart">
          <img src="@/assets/images/feather/shopping-cart.svg">
          <div v-if="cart_items_count > 0" class="cart-item-count">{{ cart_items_count }}</div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { shopMixins } from '@/mixins/shop';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  mixins: [shopMixins],
  data() {
    return {
      scrolled: false
    }
  },
  methods: {
    ...mapGetters(["getNavCategories"]),
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return
      }
      // Here we determine whether we need to show or hide the navbar
      this.scrolled = currentScrollPosition > 20
    },
    goToCart() {
      this.$router.push({ path: '/cart' });
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style lang="scss" scoped>
  .header {
    height: 80px;
    transition: all .25s ease-in-out;
    position: fixed;
    width: 100%;
    z-index: 7;
    background: #fff;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.scrolled {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    .inner {
      width: 80%;
      height: 80px;
      max-width: 1300px;
      margin: auto;
      display: flex;
      align-items: center;
      position: relative;
    }
  }

  .logo {
    width: 15%;
    height: 100%;
    max-height: 80%;
    display: flex;

    img {
      object-fit: contain;
      align-self: center;
      width: 100%;
      height: 100%;
    }
  }

  .nav {
    list-style-type: none;
    margin-right: auto;
    display: flex;
    padding-left: 20px;

    a {
      padding: 0px 5px;
      margin: auto 15px;
      text-decoration: none;
      color: black;
      float: left;
      font-family: 'Sahar-Bold';
      font-size: 12px;
      text-align: center;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition-duration: .3s;

      @include desktop {
        font-size: 15px;
      }
      
      &:hover {
        opacity: 0.4;
      }

      &.active {
        color: #292C30;
        font-family: "Silka-SemiBold", sans-serif;
      }
    }

    .v-line {
      width: 2px;
      height: 20px;
      background-color: black;
      margin: 0 10px;
    }
  }

  .icon-buttons {
    display: flex;
    align-items: center;

    img, .cart-button {
      margin: 0 10px;
      padding: 0 5px;
      cursor: pointer;
      transition-duration: .3s;
      
      &:hover {
        opacity: 0.4;
      }
    }

    .cart-button {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;

      img:hover {
        opacity: 1;
      }
    }

    .cart-item-count {
      background-color: black;
      color: white;
      height: 25px;
      font-size: 14px;
      padding: 0 10px;
      padding-top: 5px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      font-family: 'Sahar-Bold';
    }
  }
</style>