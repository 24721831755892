<template>
  <button class="big-button" :disabled="state != 'normal'">
    <div class="button-text" v-bind:class="{ hidden: state != 'normal' }">
      <slot />
    </div>

    <div class="looping-rhombuses-spinner" v-bind:class="{ visible: state == 'loading' }">
      <div class="rhombus"></div>
      <div class="rhombus"></div>
      <div class="rhombus"></div>
    </div>

    <span :class="'completion-text ' + completion_animation_state">{{ completion_text }}</span>
  </button>
</template>

<script>
export default {
  name: "BigButton",
  data() {
    return {
      state: "normal",
      completion_text: '',
      completion_animation_state: ''
    }
  },
  methods: {
    load() {
      this.state = 'loading';
    },
    complete(completion_text) {
      this.state = 'finishing';

      if (completion_text && completion_text.trim() != "") {
        this.completion_text = completion_text;
        this.completion_animation_state = "animation-in";

        setTimeout(() => {
          this.completion_animation_state = "animation-out";

          setTimeout(() => {
            this.completion_animation_state = "";
            this.state = 'normal';
          }, 300);
        }, 2000);
      } else {
        setTimeout(() => {
          this.completion_animation_state = "";
          this.state = 'normal';
        }, 300);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .big-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: none;
    background-color: black;
    color: white;
    text-transform: uppercase;
    padding: 12px 30px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: #212121;
    }

    &.disabled { // <-- custom class en GEEN standaard :disabled !!
      opacity: .4;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .button-text {
    opacity: 1;
    font-family: 'Sahar-Bold';
    transition-duration: .3s;

    &.hidden {
      opacity: 0;
    }
  }

  .completion-text {
    font-family: 'Sahar-Bold';
    position: absolute;
    line-height: 44px;
    bottom: -40px;
    opacity: 0;
    transition-duration: .3s;

    &.animation-in {
      bottom: 0;
      opacity: 1;
    }

    &.animation-out {
      bottom: 40px;
      opacity: 0;
    }
  }





  .looping-rhombuses-spinner, .looping-rhombuses-spinner * {
    box-sizing: border-box;
  }

  .looping-rhombuses-spinner {
    width: calc(15px * 4);
    height: 15px;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    margin: auto;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  .looping-rhombuses-spinner .rhombus {
    height: 15px;
    width: 15px;
    background-color: white;
    left: calc(15px * 4);
    position: absolute;
    margin: 0 auto;
    border-radius: 2px;
    transform: translateY(0) rotate(45deg) scale(0);
    animation: looping-rhombuses-spinner-animation 2500ms linear infinite;
  }

  .looping-rhombuses-spinner .rhombus:nth-child(1) {
    animation-delay: calc(2500ms * 1 / -1.5);
  }

  .looping-rhombuses-spinner .rhombus:nth-child(2) {
    animation-delay: calc(2500ms * 2 / -1.5);
  }

  .looping-rhombuses-spinner .rhombus:nth-child(3) {
    animation-delay: calc(2500ms * 3 / -1.5);
  }

  @keyframes looping-rhombuses-spinner-animation {
    0% {
      transform: translateX(0) rotate(45deg) scale(0);
    }
    50% {
      transform: translateX(-233%) rotate(45deg) scale(1);
    }
    100% {
      transform: translateX(-466%) rotate(45deg) scale(0);
    }
  }
</style>